import { Component, OnInit } from '@angular/core';
import {MessageService} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {SystemMessagingService} from '../../utilites/system-messaging.service';
import {OperatorManagerService} from '../../core/OperatorManagerService';
import {OperatorInformation} from '../../models/OperatorInformation';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  providers: [MessageService]
})
export class EditorComponent implements OnInit {

  model: OperatorInformation = new OperatorInformation();
  pageTitle = 'إضافة';
  constructor( private router: Router,
               private route: ActivatedRoute,
               private  apiService: OperatorManagerService,
               private messageService: MessageService,
               private msgService: SystemMessagingService) {

  }

  ngOnInit(): void {

    this.model.email = '';
    this.model.password = '';
    this.model.userName = '';
    this.model.operatorType = 2;

    this.route.params.subscribe(params => {

      this.model.id = +params['id']; // (+) converts string 'id' to a number
      if ( this.model.id > 0  ) {
        this.pageTitle = ' تعديل ';
        this.doRealInit(this.model.id);
      } else {
        this.model.id = 0;
      }
    });
  }

  doRealInit( Id: number ) {

    this.apiService.get( Id ).then( result => {
      this.model = result;
    });
  }

  async onSubmit() {

    // const res = await this.altService.add(this.model);
    if (this.model.id === 0) {
      this.apiService.add(this.model).then(res => {
        this.showMessage(res.isOK === true ? 'success' : 'error', this.msgService.getMessage(res.message));
        if (res.isOK) {
          setTimeout(() => {this.router.navigate(['/admin/operator']); }, 1000);
        }
      }).catch(error => {
        this.showMessage('error', this.msgService.getMessage('MSG_Auth_ERROR'));
      });
    } else {
      this.apiService.update(this.model).then(res => {
        this.showMessage(res.isOK === true ? 'success' : 'error', this.msgService.getMessage(res.message));
        if (res.isOK) {
          setTimeout(() => {this.router.navigate(['/admin/operator']); }, 1000);
        }
      }).catch(error => {
        this.showMessage('error', this.msgService.getMessage('MSG_Auth_ERROR'));
      });
    }
  }

  showMessage( severity: string, message: string ) {
    this.messageService.clear();
    this.messageService.add({ severity, summary: '', detail: message});
  }

}
