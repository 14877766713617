
export class OperatorInformation {

    id: number;
    userName: string;
    email: string;
    password: string;
    operatorType: number;
    isDisabled: boolean;
}
