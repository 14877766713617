import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponent } from './list/list.component';
import { EditorComponent } from './editor/editor.component';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {PrimeNGModule} from '../primeng.module';
import {UtilitesModule} from '../utilites/utilites.module';
import { ProfileComponent } from './profile/profile.component';


const routes: Routes = [
  { path: '', component: ListComponent },
  { path: 'add', component: EditorComponent },
  { path: 'edit/:id', component: EditorComponent },
  { path: 'profile', component: ProfileComponent }
];


@NgModule({
  declarations: [ListComponent, EditorComponent, ProfileComponent],
  imports: [
    CommonModule,
    FormsModule,
    PrimeNGModule,
//    CoreModule,
    UtilitesModule,
    RouterModule.forChild(routes)
  ]
})
export class OperatorModule { }
