import { Component, OnInit } from '@angular/core';
import {OperatorInformation} from '../../models/OperatorInformation';
import {ActivatedRoute, Router} from '@angular/router';
import {OperatorManagerService} from '../../core/OperatorManagerService';
import {MessageService} from 'primeng/api';
import {SystemMessagingService} from '../../utilites/system-messaging.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  providers: [MessageService]
})
export class ProfileComponent implements OnInit {
  
  model: OperatorInformation = new OperatorInformation();
  pageTitle = 'إضافة';
  constructor( private router: Router,
               private route: ActivatedRoute,
               private  apiService: OperatorManagerService,
               private messageService: MessageService, private msgService: SystemMessagingService) {
    
  }
  
  ngOnInit(): void {
    


    this.model.email = '';
    this.model.password = '';
    this.model.userName = '';
    this.model.operatorType = 2;
    this.doRealInit();
  }
  
  doRealInit( ) {
    
    this.apiService.profile().then( result => {
      this.model = result;
    });
  }

  onSubmit() {

    this.apiService.update(this.model).then(res => {
        this.showMessage(res.isOK === true ? 'success' : 'error', this.msgService.getMessage(res.message));
      }).catch(error => {
        this.showMessage('error', this.msgService.getMessage('MSG_GENERAL_ERROR'));
      });
  }

  showMessage( severity: string, message: string ) {
    this.messageService.clear();
    this.messageService.add({ severity, summary: '', detail: message});
  }
  
}
