import {Component, OnInit, ViewChild} from '@angular/core';
import {LazyLoadEvent, MessageService} from 'primeng/api';
import {Table} from 'primeng/table';
import {OperatorInformation} from '../../models/OperatorInformation';
import {ActivatedRoute, Router} from '@angular/router';
import {SystemMessagingService} from '../../utilites/system-messaging.service';
import {OperatorManagerService} from '../../core/OperatorManagerService';
import {PagingAndFilteringInfo} from '../../models/GeneralTransfer';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  providers: [MessageService]
})
export class ListComponent implements OnInit {

  @ViewChild('subjectTable', { static: true }) sTable: Table;
  clients: OperatorInformation[] = [];
  model: OperatorInformation = new OperatorInformation();
  totalRecords =  0;
  deleteDisplay = false;
  deleteMessage = 'هل انت متأكد من حذف هذا الموضوع؟';
  selectedId = 0;
  selectedWord = '';
  selectedWordType = '';
  cols: any[];
  loading: boolean;
  pageSize = 10;

  constructor(  private router: Router,
                private route: ActivatedRoute,
                private apiService: OperatorManagerService,
                private messageService: MessageService,
                private msgService: SystemMessagingService) {}

  async ngOnInit() {

    this.loading = true;
    this.cols = [
      { field: 'word', header: 'المادة' },
      { field: 'wordharakat', header: 'نوع المادة' }
    ];
  }

  LazyLoading( event: LazyLoadEvent ) {
    this.loading = true;
    if ( !event.sortField ) {
      event.sortField = 'username';
    }
    this.pageSize = event.rows;
    const pNo = (event.first + event.rows ) / event.rows;
    this.callService( pNo, this.pageSize, event.sortField, event.sortOrder === -1 ? false : true);
  }

  callService( pId: number, pSize: number, sOrder: string, sDirect: boolean ) {

    const paginginfo = new PagingAndFilteringInfo();
    paginginfo.index = pId;
    paginginfo.size = pSize;
    paginginfo.isAsc = sDirect;
    paginginfo.orderBy = sOrder;

    paginginfo.fields = this.getSearchFields();

    this.apiService.paging( paginginfo).subscribe( result => {
      this.clients = result.items;
      this.totalRecords = result.totalItems;

      this.loading = false;
    });
  }

  getSearchFields(): any {

    const fields: any = {};
    if ( this.selectedWord !== null && this.selectedWord.length > 0  )  {
      fields['username'] = this.selectedWord;
    }
    return fields;

  }

  showMessage( severity: string, message: string ) {
    this.messageService.clear();
    this.messageService.add({severity, summary: '', detail: message});
  }

  onEdit(rowData: any) {
    console.log( 'selectRow');
    this.router.navigateByUrl('/admin/operator/edit/' + rowData.id );
  }

  onDelete( data: OperatorInformation ) {
    this.selectedId = data.id;
    this.selectedWord = data.userName;
    this.deleteDisplay = true;
  }

  async onConfirmDelete( e: any ) {

    this.deleteDisplay = false;
    if ( e === false ) {
      return;
    }

    this.messageService.clear();
    this.selectedWord = '';
    this.selectedWordType = '';
    const res = await this.apiService.delete( this.selectedId);
    this.showMessage(res.isOK === true ? 'success' : 'error', this.msgService.getMessage ( res.message ));
    this.sTable.first = 0;
    this.callService( 1, this.pageSize, 'username', true);
    this.deleteDisplay = false;
  }
  onSearch() {
    this.callService( 1, this.pageSize, 'username', true);
  }

}
